<script>
import Layout from "../../layouts/main";
import BalanceLayout from "../../layouts/balanceLayout";
import errorHandler from "@/helpers/errorHandler";
import { required } from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
import moment from "moment";
/**
 * Starter component
 */
export default {
  page: {
    title: "Search Student",
  },
  head() {
    return {
      title: `Students-search`,
    };
  },
  validations: {
    code: {
      required,
    },
  },
  components: {
    Layout,
    BalanceLayout
  },
  methods: {
    formatDate(value){
        if(value){
            return moment(String(value)).format('hh:mm A DD/MM/YYYY')
        }
    },
    searchStudent() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.dispatch("balance/checkCode", this.code).then( token =>{
          if(token.student){
            Swal.fire({
              icon: 'success',
              title: `<h3><img src="${process.env.VUE_APP_BASE_URL}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${token.student.user.image}" alt="contact-img" title="contact-img" class="avatar-xl rounded-circle mr-2"/>` + token.student.user.name + '</h3>',
              html: `
                    <table class="table table-borderless table-sm">
                        <tbody class="row">
                            <tr class="col-md-6">
                            <th scope="row">Name:</th>
                            <td>
                                ${token.student.user.name}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Phone:</th>
                            <td >
                                ${ token.student.user.phone_number}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Stage:</th>
                            <td>
                                ${ token.student.educational_year.number + ' - ' + token.student.educational_year.description }
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">School:</th>
                            <td>
                                ${ token.student.school}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Parent phone 1:</th>
                            <td>
                                ${ token.student.phone_no_1}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Parent phone 2:</th>
                            <td>
                                ${ token.student.phone_no_2}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Activated:</th>
                            <td>
                                ${ token.student.status}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Balance:</th>
                            <td>
                                ${ token.student.balance }
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Code - Balance:</th>
                            <td>
                                ${ token.balance_codes.code + ' - ' + token.balance_codes.balance_value }
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Center:</th>
                            <td>
                                ${ token.code_dropdown.name  }
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Charged at:</th>
                            <td>
                                ${ this.formatDate(token.timestamp) }
                            </td>
                            </tr>
                        </tbody>
                        </table>
              `,
              showCancelButton: true,
              confirmButtonText: 'Show student'
              }).then( result => {
                if(result.isConfirmed){
                    this.$router.push(`/students/${token.student.stud_id}/details`);
                }
              });
            }else{
              errorHandler.methods.successful("This code still valid!!")
            }
            }).catch((error) => {
              errorHandler.methods.errorMessage(error);
        });
      }
    },
  },
  data: () => ({
    submitted:false,
    code: '',
  })
};
</script>

<template>
  <Layout>
    <BalanceLayout>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form action="#" @submit.prevent="searchStudent">
                    <div class="form-group mb-3">
                    <label for="Codeid">Code</label>
                    <input
                        class="form-control"
                        v-model="code"
                        type="text"
                        id="codeid"
                        placeholder="Enter code to check"
                        :class="{ 'is-invalid': submitted && $v.code.$error }"
                    />
                    <div
                        v-if="submitted && $v.code.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.code.required">Code is required.</span>
                    </div>
                    </div>
                    <div class="form-group mb-0 text-center">
                    <button class="btn btn-success" type="submit">
                        <i class="fas fa-search"></i>
                        Search
                    </button>
                    </div>
                </form>
            </div>
        </div>
    </BalanceLayout>
  </Layout>
</template>
